<template>
  <div class="mapbox-gl-control risk-control" :style="[offsetStyle, posStyle]">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      :left="attachLeft"
      offset-x
      z-index="10"
    >
      <!-- Button -->
      <template #activator="{ on: menu }">
        <v-tooltip :left="attachLeft" :right="!attachLeft">
          <template #activator="{ on: tooltip }">
            <v-btn
              class="pa-1 ma-2"
              height="auto"
              min-width="0"
              :disabled="!allowedActive"
              :color="active ? 'primary darken-1' : 'white'"
              small
              v-on="{ ...tooltip, ...menu }"
              @click="initLabels"
            >
              <v-icon>warning</v-icon>
            </v-btn>
          </template>
          <span>
            <slot name="tooltip">Risks</slot>
          </span>
        </v-tooltip>
      </template>

      <!-- Content -->
      <v-card outlined flat>
        <v-card-text>
          <slot />
          <v-layout d-block :style="layoutStyle">
            <!-- Listed options -->
            <template v-for="(options, key, i) in riskMenuListedOptions">
              <pibot-mapbox-risk-select
                :key="`risk-select-${key}`"
                :value="selectedRiskMap[key]"
                :items="options"
                :label="`Risk Layers - ${key}`"
                @change="(newValue) => listedChanged(key, newValue)"
                @toggle="() => toggled(key)"
              />
              <v-divider class="my-3" v-if="i < numSelectionFields -1" :key="`risk-divider-${key}`" />
            </template>

            <!-- Individual options -->
            <template v-if="numSepOptions">
              <v-divider v-if="numSelectionFields" class="my-3" />
              <!-- <p>Individual Layers</p> -->
              <template v-for="(options, key) in riskMenuSepOptions">
                <v-switch
                  v-for="(option, i) in options"
                  :key="`${key}-${i}`"
                  :value="option.value"
                  :label="option.text"
                  v-model="riskMenuSepSelected"
                  @change="() => processLayers(key)"
                  color="primary darken-1"
                  class="mt-0"
                  dense
                  multiple
                  hide-details
                />
              </template>
            </template>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import positionStyleMixin from '@/utils/mixins/styling/positionStyle.mixin.js'
import offsetStyleMixin from '@/utils/mixins/styling/offsetStyle.mixin.js'
import mapboxBasicsMixin from '@/utils/mixins/mapbox/mapboxBasics.mixin.js'
import mapboxLazyMixin from '@/utils/mixins/mapbox/mapboxLazy.mixin.js'
import riskControlMixin from '../utils/mixins/riskControl.mixin.js'

export default {
  name: 'pibot-mapbox-risk-toggle-menu',
  mixins: [positionStyleMixin, offsetStyleMixin, mapboxBasicsMixin, mapboxLazyMixin, riskControlMixin],
  components: {
    'pibot-mapbox-risk-select': () => import('./Select')
  },
  data () {
    return {
      initWatch1: false,
      initWatch2: false,
      labelsInitialized: false,
      menu: false,
      unwatch1: null,
      unwatch2: null
    }
  },
  computed: {
    active () {
      if (this.riskMenuSepSelected.length) return true // No need to continue with a loop

      let active = false
      // Check for all selection fields if there are any options selected
      for (const key in this.selectedRiskMap) {
        if (!this.selectedRiskMap[key].length) continue
        active = true
        break
      }

      return active
    },
    allowedActive () {
      return (this.numSelectionFields || this.numSepOptions)
    },
    numSelectionFields () {
      return Object.keys(this.riskMenuListedOptions).length
    },
    numSepOptions () {
      return Object.keys(this.riskMenuSepOptions).length
    }
  },
  methods: {
    initLabels () {
      if (this.labelsInitialized) return // No need to do it again

      // Save the original values
      const original = JSON.parse(JSON.stringify(this.selectedRiskMap))

      // Empty all values
      const emptyMap = {}
      for (const key in this.selectedRiskMap) {
        emptyMap[key] = []
      }
      this.setSelectedRiskMap(emptyMap)

      // Restore the original values after the component has become visible
      setTimeout(() => {
        this.setSelectedRiskMap(original)
        this.labelsInitialized = true
      }, 280) // Menu animation takes 280ms to complete
    },
    listedChanged (key, newValue) {
      this.selectedRiskMap[key] = newValue // this.selectedRiskMap has already been cloned, so this is safe to do
      this.setSelectedRiskMap(this.selectedRiskMap)
      this.processLayers(key)
    },
    toggled (key) {
      this.$nextTick(() => {
        let newValue

        if (this.selectedRiskMap[key].length === this.riskMenuListedOptions[key].length) newValue = []
        else newValue = this.riskMenuListedOptions[key].map(o => o.value)

        this.listedChanged(key, newValue)
      })
    },
    processLayers (key) {
      for (const option of this.allOptions[key]) {
        const willBeVisible = (
          (
            this.selectedRiskMap[key] &&
            this.selectedRiskMap[key].includes(option.value)
          ) ||
          (
            this.riskMenuSepSelected &&
            this.riskMenuSepSelected.includes(option.value)
          )
        )

        if (!willBeVisible) {
          // No need to lazy-load, continue as normal
          this.setLayerVisibility(option.value, willBeVisible)
          continue
        }

        // Handle lazy-loading
        this.lazyLoadSource(option.value)
          .then(() => {
            this.setLayerVisibility(option.value, willBeVisible)
          })
          .catch(error => {
            console.error(error)
          })
      }
    }
  },
  created () {
    /**
     * Setup Risk Map and it's initial values
     * @see https://vuejs.org/v2/api/#vm-watch
     * @see https://stackoverflow.com/questions/46987893/vuejs2-how-can-i-destroy-a-watcher#answer-46988117
     */
    this.unwatch1 = this.$watch(
      'numSelectionFields',
      newValue => {
        if (!newValue || this.initWatch1) return

        const listedMap = {}
        for (const key in this.riskMenuListedOptions) {
          if (this.initialRiskMenuListed[key]) listedMap[key] = this.initialRiskMenuListed[key]
          else listedMap[key] = []
        }
        this.setSelectedRiskMap(listedMap)

        this.initWatch1 = true

        // Unwatch after init
        if (this.unwatch1) this.unwatch1()
        else console.error('unable to \'unwatch\' \'unwatch1\'')
      }
    )

    /**
     * Setup initial values for seperate risk options
     * @see https://vuejs.org/v2/api/#vm-watch
     * @see https://stackoverflow.com/questions/46987893/vuejs2-how-can-i-destroy-a-watcher#answer-46988117
     */
    this.unwatch2 = this.$watch(
      'numSepOptions',
      newValue => {
        if (!newValue || this.initWatch2) return

        let initials = []
        for (const key in this.initialRiskMenuSep) {
          const toAdd = this.initialRiskMenuSep[key].map(o => o.value)
          initials = [].concat(initials, toAdd)
        }

        this.riskMenuSepSelected = initials
        this.initWatch2 = true

        // Unwatch after init
        if (this.unwatch2) this.unwatch2()
        else console.error('unable to \'unwatch\' \'unwatch2\'')
      }
    )
  }
}
</script>

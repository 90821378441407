import menuCardStyleMixin from '@/utils/mixins/styling/menuCardStyle.mixin.js'
import { mapState, mapGetters, mapMutations } from 'vuex'

export default {
  mixins: [menuCardStyleMixin],
  computed: {
    ...mapState({
      dataPending: state => state.mapbox.dataPending,
      layersPending: state => state.mapbox.layersPending,
      selectedRiskMap: state => JSON.parse(JSON.stringify(state.mapbox.selectedRiskMap))
    }),
    ...mapGetters({
      riskMenuListedOptions: 'mapbox/riskMenuListedOptions',
      riskMenuSepOptions: 'mapbox/riskMenuSepOptions',
      initialRiskMenuListed: 'mapbox/initialRiskMenuListed',
      initialRiskMenuSep: 'mapbox/initialRiskMenuSep'
    }),
    riskMenuSepSelected: {
      get () {
        return this.$store.state.mapbox.selectedSepRisks
      },
      set (newValue) {
        this.setSelectedSepRisks(newValue)
      }
    },
    allOptions () {
      return Object.assign({}, this.riskMenuListedOptions, this.riskMenuSepOptions)
    }
  },
  methods: {
    ...mapMutations({
      setSelectedRiskMap: 'mapbox/setSelectedRiskMap',
      setSelectedSepRisks: 'mapbox/setSelectedSepRisks'
    })
  }
}

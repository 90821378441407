export default {
  computed: {
    layoutStyle () {
      const styles = [
        {
          'max-width': '400px',
          width: '100%'
        }
      ]

      if (this.$vuetify.breakpoint.smAndUp) styles.push({ 'min-width': '25rem' })

      return styles
    }
  }
}
